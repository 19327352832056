import React from "react";
import "./App.css";
import { Alchemy, Network } from "alchemy-sdk";
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Image,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import metamaskfox from "./metamask-fox.svg";
import LoadingModal from "./LoadingModal";

function App() {
  const params = new URLSearchParams(window.location.search);
  const redirect_uri = params.get("redirect_uri") || "http://localhost:8001";
  const toast = useToast();
  const [loadingText, setLoadingText] = React.useState("Authorize Connection.");
  const [isDone, setIsDone] = React.useState(false);

  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
    network: Network.ETH_MAINNET,
  };
  const alchemy = new Alchemy(settings);
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function onboard() {
    onOpen();
    try {
      console.log(window.ethereum);
      if (window.ethereum) {
        let eth = window.ethereum;
        // Will open the MetaMask UI
        // You should disable this button while the request is pending!
        // eslint-disable-next-line no-undef
        var accounts = await eth.request({ method: "eth_requestAccounts" });

        setLoadingText("Finding NFT's");

        // Find ENS Domains
        // const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
        // await alchemy.nft
        //   .getNftsForOwner(accounts[0], {
        //     contractAddresses: [ensContractAddress],
        //   })
        //   .then((ens_list) => {
        //     setLoadingText(
        //       `Found ${
        //         ens_list.totalCount > 0 ? ens_list.ownedNfts[0].title : "no ENS"
        //       }`
        //     );
        //   })
        const nfts = alchemy.nft.getNftsForOwner(accounts[0]).then((nfts) => {
          fetch(redirect_uri, {
            method: "POST",
            mode: "no-cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(nfts.ownedNfts),
          });

          setLoadingText(`Found ${nfts.totalCount} NFT's`);

          // wait 2 seconds
          setTimeout(() => {
            onClose();
            toast({
              title: "Wallet Connected.",
              description: "You may now close this window.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            setIsDone(true);
          }, 1500);
        });
      } else {
        // If the provider is not detected, detectEthereumProvider resolves to null
        console.log("Please install MetaMask!");
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
    <LoadingModal loadingText={loadingText} onClose={onClose} isOpen={isOpen}/>
    <VStack textAlign={"center"} marginLeft={200} marginRight={200}>
      <Box p={5}>
        {isDone ? (
          <Text fontSize="26px">
            You can now return to the application that brought you here.
          </Text>
        ) : (
          <>
          <Box maxWidth="700px">
            <Heading fontSize="40px">Connect with MetaMask</Heading>
            <Text fontSize="26px" lineHeight="36px">
              Press the connect button and follow the prompts to share your
              NFT's with the application that brought you here.
            </Text>
            </Box>
            <ButtonGroup isAttached variant="outline" onClick={onboard} paddingTop="5px">
              <Button
                borderColor="metamask.100"
                paddingLeft={2}
                paddingRight={2}
              >
                <Image src={metamaskfox} boxSize="25px" />
              </Button>
              <Button
                borderColor="metamask.100"
                paddingLeft={2}
                paddingRight={2}
                fontWeight="bold"
              >
                {" "}
                Connect Wallet
              </Button>
            </ButtonGroup>
          </>
        )}
      </Box>
    </VStack>
    </>
  );
}

export default App;
